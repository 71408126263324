<template>
  <nav class="main-nav">
    <!-- <NavWorkspace></NavWorkspace> -->
    <NavLinks></NavLinks>
    <NavFooter></NavFooter>
  </nav>
</template>

<script>
//import NavWorkspace from './nav-workspace.vue'
import NavLinks from "./NavLinks.vue";
import NavFooter from "./footer/NavFooter.vue";

export default {
  name: "MainNav",
  components: {
    //NavWorkspace,
    NavLinks,
    NavFooter,
  },
  data() {
    return {
      greeting: "Hello from parent",
    };
  },
};
</script>
