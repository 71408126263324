<template>
  <div class="btn-col" @click="toggleImportPopup">
    <a href="#" class="btn btn-secondary-outline">
      <span class="btn-txt"
        ><span class="btn-icon icon-download"></span>Import</span
      >
    </a>
  </div>
</template>

<script>
import { useAppStore } from "@/stores/app-store";
import { mapStores } from "pinia";

export default {
  name: "ImportButton",
  computed: {
    ...mapStores(useAppStore),
  },
  methods: {
    toggleImportPopup: function () {
      console.log("toggleImportPopup");
      this.appStore.showImportPopup = !this.appStore.showImportPopup;
    },
  },
};
</script>
