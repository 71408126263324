<template>
  <div class="popup-container">

    <!-- Share Popup-->
    <div class="popup-holder popup-share" style="display: none">
      <div class="popup-wrap">
        <div class="popup-head">
          <h2>Share “Getting Started with Grain”</h2>
          <a href="#" class="popup-close icon-close"></a>
        </div>
        <div class="popup-body">
          <form action="#" class="share-form">
            <div class="form-input">
              <input
                type="text"
                id="share"
                name="share"
                placeholder="Share via email"
              />
            </div>
            <h3 class="access-list_title">People with Access</h3>
            <ul class="access-list">
              <li>
                <div class="user-wrap">
                  <div class="user-avatar">
                    <img
                      src="/images/share-avatar.svg"
                      width="42"
                      height="42"
                      alt="Image Description"
                    />
                  </div>
                  <div class="user-info">
                    <div class="user-name">Share to workspace</div>
                    <div class="user-subtxt">1 member(s)</div>
                  </div>
                </div>
                <div class="user-status">
                  <label for="switch" class="switch">
                    <input type="checkbox" id="switch" name="switch" />
                    <div class="switch-input"></div>
                  </label>
                </div>
              </li>
              <li>
                <div class="user-wrap">
                  <div class="user-avatar">
                    <img
                      src="/images/share-avatar.svg"
                      width="42"
                      height="42"
                      alt="Image Description"
                    />
                  </div>
                  <div class="user-info">
                    <div class="user-name">John Smith</div>
                    <div class="user-subtxt">
                      <a
                        href="mailto:&#109;&#097;&#105;&#108;&#064;&#101;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;"
                        >&#109;&#097;&#105;&#108;&#064;&#101;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;</a
                      >
                    </div>
                  </div>
                </div>
                <div class="user-status">
                  <div class="status-txt">owner</div>
                </div>
              </li>
            </ul>
            <div class="btn-holder">
              <div class="btn-col">
                <a href="#" class="btn btn-secondary share-btn">
                  <span class="btn-txt to-right"
                    ><span class="btn-icon icon-arrow-up"></span>Anyone with the
                    link</span
                  >
                </a>
              </div>
              <div class="btn-col">
                <a href="#" class="share-link">
                  <span class="share-icon icon-link"></span>
                  <span class="share-txt">Copy Link</span>
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <ImportPopup :showImportPopup="appStore.showImportPopup"></ImportPopup>
    <DemoPopup :showDemoPopup="appStore.showDemoPopup"></DemoPopup>
    <ExportPopup :showExportPopup="appStore.showExportPopup"></ExportPopup>
    <DeleteAccountPopupVue
      :showDeleteAccountPopup="appStore.showDeleteAccountPopup"> </DeleteAccountPopupVue>
      
    <div class="popup-holder search-tab" style="display: none">
      <div class="speaker-drop">
        <div class="speaker-scroll">
          <div class="speaker_top-bar">
            <div class="speaker_nav-bar">
              <ul class="speaker-switch">
                <li class="active">
                  <a href="#ai-notes01">Ai Notes</a>
                </li>
                <li>
                  <a href="#answers01">Answers</a>
                </li>
              </ul>
              <a href="#" class="speaker_return icon-to-left"></a>
            </div>
            <form action="#" class="search-form reply-form">
              <div class="search_input-wrap">
                <input
                  type="text"
                  id="pain-points"
                  name="pain-points"
                  placeholder="Ask a question about this conversation"
                />
                <button
                  type="submit"
                  class="search-icon icon-right-arrow"
                ></button>
              </div>
              <a href="#" class="search_close-icon icon-close"></a>
            </form>
          </div>
          <div class="speaker-drop_content">
            <div class="speaker-switch_tab tab-active" id="ai-notes01">
              <div class="ai-list_title">
                <span class="list-icon icon-bot"></span>
                <span class="title-txt">Ai-powered Notes:</span>
              </div>
              <ul>
                <li>
                  Lorem ipsum dolor sit amet consectetur. At hac id vel bibendum
                  proin phasellus habitant. Condimentum id malesuada amet vitae
                  donec mi odio in.
                </li>
                <li>
                  Lorem ipsum dolor sit amet consectetur. At hac id vel bibendum
                  proin phasellus habitant. Condimentum id malesuada amet vitae
                  donec mi odio in.
                </li>
                <li>
                  Lorem ipsum dolor sit amet consectetur. At hac id vel bibendum
                  proin phasellus habitant. Condimentum id malesuada amet vitae
                  donec mi odio in.
                </li>
              </ul>
            </div>
            <div class="speaker-switch_tab" id="answers01">
              <div class="ai-list_title">
                <span class="list-icon icon-bot"></span>
                <span class="title-txt">Ai-powered Notes:</span>
              </div>
              <ul>
                <li>
                  Lorem ipsum dolor sit amet consectetur. At hac id vel bibendum
                  proin phasellus habitant. Condimentum id malesuada amet vitae
                  donec mi odio in.
                </li>
                <li>
                  Lorem ipsum dolor sit amet consectetur. At hac id vel bibendum
                  proin phasellus habitant. Condimentum id malesuada amet vitae
                  donec mi odio in.
                </li>
                <li>
                  Lorem ipsum dolor sit amet consectetur. At hac id vel bibendum
                  proin phasellus habitant. Condimentum id malesuada amet vitae
                  donec mi odio in.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore } from "@/stores/app-store";
import { mapStores } from "pinia";

import ImportPopup from "@/components/popups/ImportPopup.vue";
import DemoPopup from "@/components/popups/DemoPopup.vue";
import ExportPopup from "@/components/popups/ExportPopup.vue";
import DeleteAccountPopupVue from "@/components/popups/DeleteAccountPopup.vue";


export default {
  name: "PopupContainer",
  components: {
    ImportPopup,
    DemoPopup,
    ExportPopup,
    DeleteAccountPopupVue,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapStores(useAppStore),
    // importPopupDisplay: function() {

    // },
  },
  mounted() {
    console.log("PopupContainer mounted");
    console.log(this.appStore.showImportPopup);
    console.log(this.appStore.showExportPopup);
  },
  methods: {},
};
</script>
