import { ref } from "vue";
import { defineStore } from "pinia";

export const useWorkSpaceStore = defineStore("workspace", () => {
  const activeWorkspaceUUID = ref("");
  const audioFiles = ref({});
  const activeAudioFile = ref(null);
  const playerCurrentTimeInMilliseconds = ref(0);
  const updatedPlayerTimeInMilliseconds = ref(0);
  const chatToken = ref({});

  const openPopupId = ref(null);

  const fileUploaded = ref(false);
  const fileDeleted = ref(false);

  const checkUserSubscription = ref(false);

  function toggleFileUploaded() {
    fileUploaded.value = !fileUploaded.value;
  }

  function setActiveWorkspaceUUID(workspaceUUID) {
    activeWorkspaceUUID.value = workspaceUUID;
  }

  function setOpenPopupId(id) {
    openPopupId.value = id;
  }

  return {
    activeWorkspaceUUID,
    setActiveWorkspaceUUID,
    openPopupId,
    setOpenPopupId,
    audioFiles,
    activeAudioFile,
    playerCurrentTimeInMilliseconds,
    updatedPlayerTimeInMilliseconds,
    fileUploaded,
    fileDeleted,
    toggleFileUploaded,
    chatToken,
  };
});
