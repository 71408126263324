<template>
  <div class="not-found">
    <h1>404</h1>
    <p>Page not found</p>
  </div>
</template>

<script>
export default {
  name: "NotFoundView",
};
</script>
