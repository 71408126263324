<template>
    <div class="settings-header">
        <router-link to="/" class="settings-close">
            <span><i class="icon-arrow-up"></i></span> Settings
        </router-link>
        <div class="settings-block">
            <!-- <div class="block">
                <strong class="title"><i class="icon-briefcase"></i>Workspace</strong>
                <ul class="sub-settings">
                    <li><a href="#">General</a></li>
                    <li><a href="#">Members</a></li>
                    <li><a href="#">Billing</a></li>
                    <li><a href="#">Plans</a></li>
                </ul>
            </div> -->
            <div class="block">
                <strong class="title"><i class="icon-user"></i>My Account</strong>
                <ul class="sub-settings">
                    <li><router-link to="/settings">Profile</router-link></li>
                    <li><router-link to="/settings/billing">Billing</router-link></li>
                    <li><router-link to="/settings/plans">Plans</router-link></li>
                
                </ul>
            </div>
            <!-- <div class="block">
                <strong class="title"><i class="icon-key"></i>Integrations</strong>
                <ul class="sub-settings">
                    <li><a href="#">Zoom</a></li>
                    <li><a href="#">Product Board</a></li>
                    <li><a href="#">Slack</a></li>
                    <li><a href="#">Zapier</a></li>
                </ul>
            </div> -->
            <div class="block">
                <strong class="title">
                    <a @click="handleLogout" href="#"><i class="icon-signout"></i>Sign Out</a>
                </strong>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SettingsNavigation",
    methods: {
    handleLogout() {
      // Implement the logout logic here
      this.$auth0.logout();
    },
  },
};
</script>