<template>
  <header class="header nav-bar" id="header">
    <a @click="toggleNav" href="#" class="nav-opener icon-to-left"
      ><span></span
    ></a>
    <div class="navbar_scroll">
      <div class="navbar_width">
        <Logo></Logo>
        <MainNav></MainNav>
      </div>
    </div>
  </header>
</template>

<script>
import Logo from "@/components/navigation/Logo.vue";
import MainNav from "@/components/navigation/MainNav.vue";

export default {
  name: "NavBar",
  components: {
    Logo,
    MainNav,
  },
  data() {
    return {
      greeting: "Hello from parent",
    };
  },
  methods: {
    toggleNav() {
      console.log("Toggleing the Nav");
      document.body.classList.toggle("nav-active");
    },
  },
};
</script>
