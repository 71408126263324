<template>
    <SettingsLayout>
        <Suspense>
            <div class="settings-content">
            <div class="heading">
                <h1 class="h2">Billing</h1>
            </div>
            <div class="block">
                <div class="mxw">
                    <p v-if="billingPortalUrl">
                        To access your billing information please click the link below:
                    </p>
                    <p v-if="billingPortalUrl">
                        <a :href="billingPortalUrl">Billing portal</a>
                    </p>
                    <p v-else>
                        It seems that you are not currently subscribed. Please <router-link to="/settings/plans">click here</router-link> to view our subscription plans and subscribe.
                    </p>
                </div>
            </div>
        </div>

        <template #fallback> 
            Loading...
        </template>
        </Suspense>
    </SettingsLayout>
</template>

<script>
import SettingsLayout from "@/components/SettingsLayout.vue";
import { createCustomerPortalSession } from "@/services/workspace.service.js";
import { useWorkSpaceStore } from "@/stores/workspace-store";
import { mapStores } from "pinia";

export default {
    name: "SettingsBillingView",
    components: {
        SettingsLayout,
    },
    data() {
        return {
            billingPortalUrl: null,
        };
    },
    computed: {
        ...mapStores(useWorkSpaceStore),
    },
    methods: {
        async fetchBillingPortalUrl() {
            try {
                const response = await createCustomerPortalSession();
                this.billingPortalUrl = response.data.customerPortalUrl; // Assuming the response contains the URL in data.customerPortalUrl
            } catch (error) {
                console.error("Failed to fetch billing portal URL", error);
                this.billingPortalUrl = null; // Ensure billingPortalUrl is null on failure
            }
        },
    },
    async beforeMount() {
        await this.fetchBillingPortalUrl(); // Fetch the billing portal URL when the component is mounted
    },
};
</script>
