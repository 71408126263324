<template>
  <div class="top-bar">
    <div class="container container-fluid">
      <div class="top-bar_wrap">
        <Search></Search>
        <div class="btn-group">
          <!-- <div class="btn-col">
            <form action="#" class="record-url_form">
              <div class="input-wrap">
                <span class="input-icon icon-link"></span>
                <input
                  type="text"
                  id="record-url"
                  name="record-url"
                  placeholder="Paste meeting url to record"
                />
              </div>
              <button type="submit" class="btn btn-secondary">
                <span class="btn-txt"
                  ><span class="btn-icon icon-voice-search"></span>Record</span
                >
              </button>
            </form>
          </div> -->
          <ImportButton v-if="!isTranscriptionRoute"></ImportButton>
          <!-- <ShareButton v-if="isTranscriptionRoute"></ShareButton> -->
          <!-- <DownloadButton v-if="isTranscriptionRoute"></DownloadButton> -->
          <ExportButton v-if="isTranscriptionRoute"></ExportButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImportButton from "@/components/top-bar/ImportButton.vue";
import ExportButton from "@/components/top-bar/ExportButton.vue";
import ShareButton from "@/components/top-bar/ShareButton.vue";
import DownloadButton from "@/components/top-bar/DownloadButton.vue";
import Search from "@/components/top-bar/Search.vue";

export default {
  name: "TopBar",
  components: {
    ImportButton,
    ExportButton,
    ShareButton,
    DownloadButton,
    Search,
  },
  computed: {
    isTranscriptionRoute() {
      return this.$route.path.includes('/transcription');
    },
  },
};
</script>
