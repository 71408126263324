<template>
</template>

<script>
import { useAuth0 } from "@auth0/auth0-vue";

export default {
  name: "SignupView",
  created() {
    const { loginWithRedirect } = useAuth0();

    loginWithRedirect({
      appState: {
        target: "/",
      },
      authorizationParams: {
        screen_hint: "signup",
      }
    });
  },
};
</script>
