import "./assets/main.scss";

import { createApp } from "vue";
import { createPinia } from "pinia";
import * as Sentry from "@sentry/vue";

import { auth0 } from "./services/auth0";
import filters from "./helpers/filters";

import InstantSearch from "vue-instantsearch/vue3/es";

import App from "./App.vue";
import router from "./router";

import VuePlyr from "@skjnldsv/vue-plyr";
import "@skjnldsv/vue-plyr/dist/vue-plyr.css";

import vClickOutside from "v-click-outside";

import Toaster from "@meforma/vue-toaster";

import Vue3Sanitize from "vue-3-sanitize";

const app = createApp(App);
//app.config.globalProperties.$filters = filters;

app.use(createPinia());
Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost",
        "https://audioscribe.io",
        "https://audioscribe-ui-js-docker-staging-v3k2kqnmba-uk.a.run.app",
        "https://audioscribe-ui-js-docker-prod-v3k2kqnmba-uk.a.run.app",
        "https://audioscribe-ui-js-docker-dev-v3k2kqnmba-uk.a.run.app",
      ],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app
  .use(router)
  .use(auth0)
  .use(VuePlyr)
  .use(vClickOutside)
  .use(InstantSearch)
  .use(Vue3Sanitize)
  .use(Toaster);

app.mount("#app");
