// src/utils/downloadHelpers.js

export async function downloadFile(data, fileName, format = "txt") {
  console.log(`Format received: ${format}`); // Add this line to log the format

  let mimeType;

  switch (format) {
    case "pdf":
      mimeType = "application/pdf";
      break;
    case "srt":
      mimeType = "text/srt";
      break;
    case "vtt":
      mimeType = "text/vtt";
      break;
    case "txt":
    default:
      mimeType = "text/plain";
      break;
  }

  const blob = new Blob([data], { type: mimeType });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${fileName}.${format}`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}
