<template>
  <div v-if="!loading" class="popup-holder popup-import" :style="[showImportPopup ? '' : 'display:none']">
    <div class="popup-wrap">
      <div class="popup-head">
        <h2>Transcribe Audio and Video Files</h2>
        <a @click="closeImportPopup" href="#" class="popup-close icon-close"></a>
      </div>
      <div class="popup-body">
        <div class="">
          <!-- Conditionally render Uppy dashboard or no uploads remaining message -->
          <div v-if="!hasExceededLimit">
            <Dashboard
              :uppy="uppy"
              :props="{
                metaFields: [
                  { id: 'name', name: 'Name', placeholder: 'File name' },
                ],
                proudlyDisplayPoweredByUppy: false,
              }"
              style="width: 100%; height: 300px"
            />
          </div>
          <div v-else class="exceeded-limit-message">
            <p>No more uploads remain for today. Please upgrade to get more file uploads.</p>
            <router-link to="/settings/plans" class="btn">Upgrade now</router-link>
          </div>
        </div>
      </div>
      <div class="popup-foot" v-if="!isSubscribed">
        <!-- Display remaining uploads -->
        <div class="import-counts">
          <span>{{ remainingFiles }} of {{ dailyLimit }} uploads left</span>
        </div>
        <div class="btn-holder">
          <router-link to="/settings/plans" class="btn btn-secondary-outline">Upgrade for unlimited imports</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isSubscribed, hasUserExceededFileUploadLimit, createTempAudioFile } from "@/services/workspace.service";
import { useWorkSpaceStore } from "@/stores/workspace-store";
import { useAppStore } from "@/stores/app-store";
import { mapStores } from "pinia";

import Uppy from "@uppy/core";
import Transloadit from "@uppy/transloadit";
import { Dashboard } from "@uppy/vue";

export default {
  name: "ImportPopup",
  props: ["showImportPopup"],
  components: {
    Dashboard,
  },
  computed: {
    ...mapStores(useAppStore),
    ...mapStores(useWorkSpaceStore),
    uppy() {
      let userUUID = this.appStore?.user?.userUUID;
      let workspaceUUID = this.workspaceStore?.activeWorkspaceUUID;

      console.log("The values passed to Uppy are: ");
      console.log(userUUID);
      console.log(workspaceUUID);

      const assemblyId = this.isSubscribed 
        ? import.meta.env.VITE_TRANSLOADIT_ASSEMBLY_ID 
        : import.meta.env.VITE_TRANSLOADIT_FREE_USER_ASSEMBLY_ID;

      const uppyInstance = new Uppy({
        id: "uppy1",
        autoProceed: true,
        debug: true,
        proudlyDisplayPoweredByUppy: false,
        restrictions: {
          maxNumberOfFiles: 1,
          allowedFileTypes: [
            ".3ga", ".8svx", ".aac", ".ac3", ".aif", ".aiff", ".alac", ".amr", ".ape", ".au", ".dss", ".flac", ".flv",
            ".m4a", ".m4b", ".m4p", ".m4r", ".mp3", ".mpga", ".ogg", ".oga", ".mogg", ".opus", ".qcp", ".tta", ".voc",
            ".wav", ".wma", ".wv", ".mov", ".mp4",
          ],
        },
      });

      uppyInstance
        .use(Transloadit, {
          waitForEncoding: false,
          waitForMetadata: true,
          alwaysRunAssembly: true,
          assemblyOptions: {
            params: {
              template_id: assemblyId,
              notify_url: this.TRANSLOADIT_NOTIFY_URL,
              auth: {
                key: import.meta.env.VITE_TRANSLOADIT_AUTH_KEY,
              },
            },
            fields: {
              userUUID: userUUID,
              workspaceUUID: workspaceUUID,
            },
          },
        })
        .on("transloadit:complete", async (result) => {
          console.log("Transloadit:Complete Event fired!");
          console.log(result);

          // Create the temporary Audio File
          const createTempAudioFileDTO = {
            transloaditAssemblyId: result.assembly_id,
            originalFileName: result?.uploads[0]?.name,
          };
          await createTempAudioFile(workspaceUUID, createTempAudioFileDTO);
          this.workspaceStore.fileUploaded = true;

          // Check the upload limit after each file upload
          await this.checkSubscriptionAndLimit();

          const routeName = this.$route.name;
          if (routeName !== "conversations") {
            setTimeout(() => {
              this.closeImportPopup();
              this.$router.push({ path: "/conversations" });
            }, 1000);
          }
        })
        .on("transloadit:upload", (result) => {
          console.log("Transloadit:Upload Event fired!");
          console.log(result);
        });

      return uppyInstance;
    },
  },
  data() {
    return {
      TRANSLOADIT_NOTIFY_URL: import.meta.env.VITE_TRANSLOADIT_NOTIFY_URL,
      isSubscribed: false,
      hasExceededLimit: false,
      remainingFiles: 0,
      dailyLimit: 3, // Set your daily limit here, adjust as needed
      loading: true, // Add a loading state
    };
  },
  async mounted() {
    await this.checkSubscriptionAndLimit();
  },
  methods: {
    closeImportPopup() {
      console.log("toggleImportPopup");
      this.appStore.showImportPopup = false;
    },
    async checkSubscriptionAndLimit() {
      try {
        const subscriptionResponse = await isSubscribed();
        this.isSubscribed = subscriptionResponse.data.isSubscribed;

        if (!this.isSubscribed) {
          const limitResponse = await hasUserExceededFileUploadLimit(this.workspaceStore.activeWorkspaceUUID);
          this.hasExceededLimit = limitResponse.data.hasExceededLimit;
          this.remainingFiles = Math.max(0, limitResponse.data.remainingFiles);
        }

        this.loading = false; // Set loading to false after data is fetched
      } catch (error) {
        console.error("Failed to check subscription status or file upload limit", error);
        this.loading = false; // Ensure the component renders even on error
      }
    },
  },
  watch: {
    showImportPopup(newVal) {
      if (newVal) {
        console.log("ImportPopup is visible");
        this.checkSubscriptionAndLimit();
      }
    },
  },
};
</script>

<style src="@uppy/core/dist/style.css"></style>
<style src="@uppy/dashboard/dist/style.css"></style>
<style>
.uppy-Dashboard {
  width: 100%;
  height: 300px;
}

.exceeded-limit-message {
  text-align: center;
  color: red;
}

.exceeded-limit-message p {
  margin-bottom: 16px;
}

.import-counts {
  text-align: center;
  margin-top: 20px;
}

.btn-holder {
  text-align: center;
  margin-top: 20px;
}
</style>
