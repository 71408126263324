<template>
  <div class="main-nav_section">
    <ul class="nav-list">
      <NavLink path="/" label="Home" icon="icon-home"></NavLink>
      <NavLink
        path="/conversations"
        label="Conversations"
        icon="icon-conversation"
      ></NavLink>
    </ul>
  </div>
</template>

<script>
import NavLink from "./NavLink.vue";
export default {
  name: "NavLinks",
  components: {
    NavLink,
  },
  data() {
    return {
      greeting: "Hello from parent",
    };
  },
};
</script>
