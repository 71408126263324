<template>
  <div class="popup-holder popup-import" :style="[showDemoPopup ? '' : 'display:none']">
    <div class="popup-wrap  demo-popup">
      <div class="popup-head">
        <!-- <h2>AudioScribe.io Demo</h2> -->
        <a @click="closeDemoPopup" href="#" class="popup-close icon-close"></a>
      </div>
      <div class="popup-body" style="padding:0px;">
        <div class="">
          <div
            style="position: relative; box-sizing: content-box; max-height: 100vh; max-height: 100svh; width: 100%; aspect-ratio: 1.9178690344062153; padding: 40px 0 40px 0;">
            <iframe src="https://app.supademo.com/embed/clzosqmrq098tgumtmz4zlsco?embed_v=2" loading="lazy"
              title="Audioscribe.io Demo" allow="clipboard-write" frameborder="0" webkitallowfullscreen="true"
              mozallowfullscreen="true" allowfullscreen
              style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe>
          </div>
        </div>
      </div>
      <!-- <div class="popup-foot">
        <div class="import-counts">10 of 10 imports left</div>
        <div class="btn-holder">
          <a href="#" class="btn btn-secondary-outline"
            >Upgrade to Business for unlimited imports</a
          >
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { createTempAudioFile } from "@/services/workspace.service";
import { useWorkSpaceStore } from "@/stores/workspace-store";
import { useAppStore } from "@/stores/app-store";
import { mapStores } from "pinia";

import Uppy from "@uppy/core";
import Tus from "@uppy/tus";
import Transloadit, { COMPANION_URL } from "@uppy/transloadit";
import { Dashboard } from "@uppy/vue";

export default {
  name: "DemoPopup",
  props: ["showDemoPopup"],
  components: {
    Dashboard,
  },
  computed: {
    ...mapStores(useAppStore),
    ...mapStores(useWorkSpaceStore),
    uppy() {
      let userUUID = this.appStore?.user?.userUUID;
      let workspaceUUID = this.workspaceStore?.activeWorkspaceUUID;
      //var createTempAudioFile = createTempAudioFile;

      console.log("The values passed to Uppy are: ");
      console.log(userUUID);
      console.log(workspaceUUID);

      return new Uppy({
        id: "uppy1",
        autoProceed: true,
        debug: true,
        proudlyDisplayPoweredByUppy: false,
        restrictions: {
          maxNumberOfFiles: 1,
          allowedFileTypes: [
            ".3ga",
            ".8svx",
            ".aac",
            ".ac3",
            ".aif",
            ".aiff",
            ".alac",
            ".amr",
            ".ape",
            ".au",
            ".dss",
            ".flac",
            ".flv",
            ".m4a",
            ".m4b",
            ".m4p",
            ".m4r",
            ".mp3",
            ".mpga",
            ".ogg",
            ".oga",
            ".mogg",
            ".opus",
            ".qcp",
            ".tta",
            ".voc",
            ".wav",
            ".wma",
            ".wv",
            ".mov",
            ".mp4",
          ],
        },
      })
        .use(Transloadit, {
          waitForEncoding: false,
          waitForMetadata: true,
          alwaysRunAssembly: true,
          assemblyOptions: {
            params: {
              template_id: import.meta.env.VITE_TRANSLOADIT_ASSEMBLY_ID,
              notify_url: this.TRANSLOADIT_NOTIFY_URL,
              // To avoid tampering, use Signature Authentication:
              // https://transloadit.com/docs/topics/signature-authentication/
              auth: {
                key: import.meta.env.VITE_TRANSLOADIT_AUTH_KEY,
              },
            },
            fields: {
              userUUID: userUUID,
              workspaceUUID: workspaceUUID,
            },
          },
        })
        .on("transloadit:complete", async (result) => {
          console.log("Transloadit:Complete Event fired!");
          console.log(result);
          const createTempAudioFileDTO = {
            transloaditAssemblyId: result.assembly_id,
            originalFileName: result?.uploads[0]?.name,
          };
          // Create the temporary Audio File:
          await createTempAudioFile(workspaceUUID, createTempAudioFileDTO);
          this.workspaceStore.fileUploaded = true;

          const routeName = this.$route.name;
          if (routeName !== "conversations") {
            setTimeout(() => {
              this.closeDemoPopup();
              this.$router.push({ path: "/conversations" });

            }, 1000);
          }


        })
        .on("transloadit:upload", (result) => {
          console.log("Transloadit:Upload Event fired!");
          console.log(result);
        })
    },
  },
  data() {
    return {
      greeting: "Hello from parent",
      TRANSLOADIT_NOTIFY_URL: import.meta.env.VITE_TRANSLOADIT_NOTIFY_URL,
    };
  },
  methods: {
    closeDemoPopup: function () {
      console.log("toggleDemoPopup");
      this.appStore.showDemoPopup = false;
    },
  },
};
</script>

<style src="@uppy/core/dist/style.css"></style>
<style src="@uppy/dashboard/dist/style.css"></style>
<style>
.demo-popup {
  max-width: 700px;
}
</style>
