<!-- <template>
    <div class="btn-group">
                  <div class="btn-col">
                    <a href="#" class="btn btn-secondary-outline">
                      <span class="btn-txt"><span class="btn-icon icon-share"></span>Share</span>
                    </a>
                  </div>
                </div>
</template> -->

<template>
  <div class="btn-col" @click="toggleImportPopup">
    <a href="#" class="btn btn-secondary-outline">
      <span class="btn-txt"
        ><span class="btn-icon icon-share"></span>Share</span
      >
    </a>
  </div>
</template>

<script>

export default {
  name: "ShareButton",
};

</script>