<template>
  <div class="loader">
    <img :src="loadingImg" alt="Loading..." />
  </div>
</template>

<script>
export default {
  name: "PageLoader",
  data() {
    return {
      loadingImg: "https://cdn.auth0.com/blog/hello-auth0/loader.svg",
    };
  },
};
</script>

<style scoped>
.loader {
  display: flex; /* Use flexbox for alignment */
  justify-content: center; /* Horizontally center content */
  align-items: center; /* Vertically center content */
  height: 100vh; /* 100% of the viewport height */
  width: 100vw; /* 100% of the viewport width */
}

.loader img {
  width: 50px;
  height: 50px;
}
</style>
