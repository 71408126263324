import { createRouter, createWebHistory } from "vue-router";
import { authGuard } from "@auth0/auth0-vue";
import HomeView from "@/views/HomeView.vue";
import CallbackView from "@/views/CallbackView.vue";
import NotFoundView from "@/views/NotFoundView.vue";
import SignupView from "@/views/SignupView.vue";
import SettingsProfileView from "@/views/SettingsProfileView.vue";
import SettingsStripeRedirect from "@/views/SettingsStripeRedirect.vue";
import SettingsPlansView from "@/views/SettingsPlansView.vue";
import SettingsBillingView from "@/views/SettingsBillingView.vue";
import PaymentSuccessView from "@/views/PaymentSuccessView.vue";
import PaymentCancelView from "@/views/PaymentCancelView.vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      component: HomeView,
      beforeEnter: authGuard,
    },
    {
      path: "/signup",
      name: "signup",
      component: SignupView,
    },
    {
      path: "/settings",
      name: "settings",
      component: SettingsProfileView,
      beforeEnter: authGuard,
    },
    {
      path: "/settings/plans",
      name: "settings-plans",
      component: SettingsPlansView,
      beforeEnter: authGuard,
    },
    {
      path: "/settings/stripe-redirect/:promoCode",
      name: "settings-stripe-redirect",
      component: SettingsStripeRedirect,
      beforeEnter: authGuard,
    },
    {
      path: "/settings/billing",
      name: "settings-billing",
      component: SettingsBillingView,
      beforeEnter: authGuard,
    },
    {
      path: "/conversations",
      name: "conversations",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/ConversationsView.vue"),
      beforeEnter: authGuard,
    },
    {
      path: "/transcription/:audioFileUUID",
      name: "transcription",
      component: () => import("../views/TranscriptionView.vue"),
      beforeEnter: authGuard,
    },
    {
      path: "/callback",
      name: "callback",
      component: CallbackView,
    },
    {
      path: "/payment/success",
      name: "payment-success",
      component: PaymentSuccessView,
    },
    {
      path: "/payment/cancel",
      name: "payment-cancel",
      component: PaymentCancelView,
    },
    {
      path: "/:catchAll(.*)",
      name: "Not Found",
      component: NotFoundView,
    },
  ],
});

export default router;
