<template>
  <div class="logo">
    <router-link to="/"
      ><img
        src="/images/logo-dark.svg"
        width="194"
        height="96"
        alt="Audioscribe"
    /></router-link>
  </div>
</template>

<style scoped>
img{
  cursor:grab;
}
</style>
