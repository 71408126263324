<template>
  <router-link
    :to="path"
    v-slot="{ href, route, navigate, isActive, isExactActive }"
    custom
  >
    <li :class="[{ active: isActive }]">
      <a :href="href" @click="navigate">
        <span :class="['nav-icon', icon ? icon : '']"></span>
        <div class="nav-txt">{{ label }}</div>
      </a>
    </li>
  </router-link>
</template>

<script>
export default {
  name: "NavLink",
  props: ["path", "label", "icon"],
  methods: {
    navigate() {
      // Implement the navigation logic here
    },
  },
  computed: {
    isActive() {
      // Implement the isActive logic here
    },
  },
};
</script>
