<template>
  <a class="accessibility" href="#main">Skip to Content</a>
  <noscript>
    <div>Javascript must be enabled for the correct page display</div>
  </noscript>
  <div class="wrapper">
    <div class="content-wrap">
      <NavBar />
      <main class="main content-holder" id="main">
        <TopBar />
        <slot />
      </main>
    </div>
    <PageFooter />
  </div>
</template>

<script>
import PageFooter from "@/components/PageFooter.vue";
import TopBar from "@/components/TopBar.vue";
import NavBar from "@/components/navigation/NavBar.vue";

export default {
  name: "CallBackView",
  components: {
    PageFooter,
    TopBar,
    NavBar,
  },
};
</script>
