<template>
  <div class="btn-group">
    <div class="btn-col">
      <a href="#" class="btn btn-secondary-outline" @click.prevent="downloadTranscript">
        <span class="btn-txt">
          <span class="btn-icon icon-download"></span>Download Transcript
        </span>
      </a>
    </div>
    <!-- Hidden link for initiating download -->
    <a ref="downloadLink" style="display: none;"></a>
  </div>
</template>

<script>
import { downloadTranscript } from "@/services/workspace.service";
import { useWorkSpaceStore } from "@/stores/workspace-store";
import { downloadFile } from "@/utils/downloadHelpers";
import { mapStores } from "pinia";

export default {
  name: "DownloadButton",
  computed: {
    ...mapStores(useWorkSpaceStore),
    audioFile() {
      return this.workspaceStore.activeAudioFile;
    },
  },
  methods: {
    async downloadTranscript() {
      if (!this.audioFile || this.audioFile.processingStatus !== 'COMPLETED') {
        this.$toast.error("Transcript is not available for download.");
        return;
      }

      try {
        this.$toast.success(`Preparing to download transcript...`);
        const response = await downloadTranscript(this.workspaceStore.activeWorkspaceUUID, this.audioFile.audioFileUUID);
        const lastPeriodIndex = this.audioFile.userDefinedName.lastIndexOf('.');
        const fileName = lastPeriodIndex !== -1 ? this.audioFile.userDefinedName.substring(0, lastPeriodIndex) : this.audioFile.userDefinedName;
        downloadFile(response.data.body, fileName);
      } catch (error) {
        console.error("Failed to download transcript:", error);
        this.$toast.error("Failed to download transcript.");
      }
    },
  },
};
</script>
