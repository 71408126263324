import { callExternalApi } from "./external-api.service";
//import { useAuth0 } from "@auth0/auth0-vue";
import { auth0 } from "./auth0";

const API_SERVER_URL = import.meta.env.VITE_API_SERVER_URL;

export const search = async (workspaceUUID, searchQuery) => {
  console.log("Called the getAudioFiles method");

  const config = {
    url: `${API_SERVER_URL}/workspace/${workspaceUUID}/search`,
    method: "POST",
    data: {
      query: searchQuery,
    },
  };
  return await secureApiCallTemplate(config);
};

export const secureApiCallTemplate = async (configParams, token) => {
  console.log("Called the getUserWorkspaces method");
  //const { getAccessTokenSilently } = useAuth0();
  //const { loginWithRedirect } = useAuth0();
  let accessToken;
  try {
    accessToken = await getAccessTokenSilentlyOutsideComponent();
    //console.log("accessToken", accessToken);
  } catch (e) {
    if (e.error === "login_required") {
      //loginWithRedirect()
    }
    if (e.error === "consent_required") {
      //loginWithRedirect()
    }
    throw e;
  }

  const config = {
    ...configParams,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export async function getAccessTokenSilentlyOutsideComponent(options) {
  return auth0.getAccessTokenSilently(options);
}
