<template>
  <div class="search-col">
    <form @submit.prevent="search" class="search-form main-search" autocomplete="off">
      <!-- Input for search -->
      <input type="search" id="searchField" name="searchField" placeholder="Search All Transcripts" autocomplete="off"
        v-model="searchField" @keydown.escape="clearSearch" />
      <!-- Search icon and its animation -->
      <!-- <button type="submit" class="search-icon" :class="isLoading ? 'icon-setting' : 'icon-search'"></button> -->
      <button type="submit" class="search-icon icon-search"></button>
      <a href="#" class="search_close-icon icon-close"></a>
      <!-- Search results dropdown -->
      <div class="search-drop" v-click-outside="onClickOutside">
        <div class="result-filter">
          <div class="result-count" v-if="resultsCount > 0">
            {{ resultsCount }} Results
          </div>
          <span v-if="isLoading" class="icon-setting"></span>
          <!-- Display spinner when loading -->
        </div>
        <div class="result_block">
          <!-- Display when there's no results -->
          <div class="no-result_block" v-if="resultsCount == 0">
            <h2 class="h3">No Results</h2>
            <p class="margin-0">
              We couldn’t find any conversations related to your search. You may
              want to try…
            </p>
            <ul>
              <li>Using different keywords</li>
              <li>Checking for typos</li>
              <li>Adjusting your filters</li>
            </ul>
          </div>
          <!-- Display results -->
          <a :href="`/transcription/${result.audio_file_uuid}`" class="result-card" v-for="result in searchResults"
            :key="result.audiofile_uuid">
            <h2>{{ result.original_file_name }}</h2>
            <div class="result-date">
              <span class="date-icon icon-calendar"></span>
              <span class="date-txt">{{ formatDate(result.created_at) }}</span>
            </div>
            <p v-html="result?.highlight?.transcription_text?.snippet"></p>
          </a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>

import { search } from "@/services/useSearch.service";
import { useWorkSpaceStore } from "@/stores/workspace-store";
import { mapStores } from "pinia";
import moment from "moment";

const clickOutsideDirective = {
  beforeMount(el, binding) {
    el.clickOutsideEvent = function (event) {
      // Get the reference to the search form elements
      //const searchForm = el.querySelector('.search-form');
      const searchForm = el.parentElement;
      // Check if the event target is part of the excluded elements
      if (!(searchForm.contains(event.target))) {
        binding.value(event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
    document.body.addEventListener("pointerdown", el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
    document.body.removeEventListener("pointerdown", el.clickOutsideEvent);
  },
};



export default {
  name: "Search",
  directives: {
    clickOutside: clickOutsideDirective,
  },
  computed: {
    ...mapStores(useWorkSpaceStore),
    resultsCount: function () {
      return this.searchResults?.length > 0 ? this.searchResults.length : 0;
    },
  },
  data() {
    return {
      searchField: "",
      noResults: false,
      searchResults: [],
      isLoading: false, // New data property for loading indicator
    };
  },
  methods: {
    async search() {
      if (!this.searchField.trim()) {
        this.clearSearch();
        return;
      }
      this.isLoading = true; // Start loading animation
      console.log(`Search for ${this.searchField}`);

      const searchResults = await search(
        this.workspaceStore.activeWorkspaceUUID,
        this.searchField,
      );
      this.searchResults = searchResults.data;
      console.log(searchResults);

      this.isLoading = false; // End loading animation
      document.body.classList.add("search-active");
    },
    clearSearch() {
      this.searchField = "";
      document.body.classList.remove("search-active");
    },
    onClickOutside(event) {
      //console.log("Clicked outside. Event: ", event);
      this.clearSearch();
    },
    formatDate(value) {
      return moment.unix(value).format("MMMM Do, YYYY");
    },
    formatTime(value) {
      return moment.unix(value).format("h:mm A");
    },
  },
};



</script>

<!-- Add styles for loading animation -->
<style scoped>
.icon-setting {
  /* Add styles for the spinner */
  /* For example, if you're using a rotating animation: */
  animation: rotate 1s linear infinite;
  margin-left: 5px;
  /* Adjust spacing as per your design */
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
